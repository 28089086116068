import React, { useState, useEffect } from "react";
import axios from "axios";

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [userActivities, setUserActivities] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchUsers();
    fetchActivities();
    fetchUserActivities();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/admin/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setMessage(error.response?.data?.detail || "Error fetching users");
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get("/api/admin/activities");
      setActivities(response.data);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setMessage(error.response?.data?.detail || "Error fetching activities");
    }
  };

  const fetchUserActivities = async () => {
    try {
      const response = await axios.get("/api/admin/activities");
      setUserActivities(response.data);
    } catch (error) {
      console.error("Error fetching user activities:", error);
      setMessage(
        error.response?.data?.detail || "Error fetching user activities"
      );
    }
  };

  const updateUser = async (id, role, points, blocked) => {
    try {
      const response = await axios.put(`/api/admin/users/${id}`, {
        role,
        points,
        blocked,
      });
      setMessage(response.data.message);
      fetchUsers();
      fetchUserActivities();
    } catch (error) {
      console.error("Error updating user:", error.response?.data);
      setMessage("Error updating user");
    }
  };

  const deleteUser = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this user? This action cannot be undone."
      )
    ) {
      try {
        const response = await axios.delete(`/admin/users/${id}`);
        setMessage(response.data.message);
        fetchUsers();
        fetchUserActivities();
      } catch (error) {
        console.error("Error deleting user:", error.response?.data);
        setMessage("Error deleting user");
      }
    }
  };

  const roles = ["user", "premium", "admin"];

  return (
    <div>
      <h2>Admin Page</h2>
      <p>{message}</p>
      <h3>Users</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Role</th>
            <th>Points</th>
            <th>Blocked</th>
            <th>Phone Number</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>
                <select
                  value={user.role}
                  onChange={(e) =>
                    updateUser(
                      user.id,
                      e.target.value,
                      user.points,
                      user.blocked
                    )
                  }
                >
                  {roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={user.points}
                  onChange={(e) =>
                    updateUser(user.id, user.role, e.target.value, user.blocked)
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={user.blocked}
                  onChange={(e) =>
                    updateUser(
                      user.id,
                      user.role,
                      user.points,
                      e.target.checked
                    )
                  }
                />
              </td>
              <td>{user.phoneNumber || "N/A"}</td>
              <td>{user.gender || "N/A"}</td>
              <td>{user.address || "N/A"}</td>
              <td>
                <button
                  onClick={() =>
                    updateUser(user.id, user.role, user.points, user.blocked)
                  }
                >
                  Update
                </button>
                <button onClick={() => deleteUser(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>User Activities</h3>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>User Email</th>
            <th>Activity Type</th>
            <th>IP Address</th>
            <th>Timestamp</th>
            <th>Details</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {userActivities.map((activity) => (
            <tr key={activity.id}>
              <td>{activity.id}</td>
              <td>{activity.user_email}</td>
              <td>{activity.activity_type}</td>
              <td>{activity.ip_address}</td>
              <td>{new Date(activity.timestamp).toLocaleString()}</td>
              <td>{activity.details || "N/A"}</td>
              <td>
                {activity.activity_type === "search" ? activity.answer : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminPage;

