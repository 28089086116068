import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8000",
  withCredentials: true,
});

function QASection({ isPremium, points, setPoints }) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [retrievedDocs, setRetrievedDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    console.log("[QASection] Component mounted, fetching names...");
    console.log("[QASection] Current baseURL:", api.defaults.baseURL); // baseURL 확인 로그
    fetchNames();
  }, []);

  const fetchNames = async () => {
    try {
      console.log("[QASection] Fetching available names...");
      const response = await api.get("/names");
      console.log("[QASection] GET /names request URL:", response.config.url); // 요청 URL 확인 로그
      console.log("[QASection] Response data:", response.data); // 응답 데이터 확인
      setNames(response.data.names);
      if (response.data.names.length > 0) {
        setSelectedName(response.data.names[0]);
      }
      console.log(
        "[QASection] Names fetched successfully:",
        response.data.names
      );
    } catch (error) {
      console.error("[QASection] Error fetching names:", error);
      if (error.response) {
        console.error(
          "[QASection] GET /names server responded with:",
          error.response.status,
          error.response.data
        );
      } else {
        console.error("[QASection] Error message:", error.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    if (!question.trim()) {
      alert("질문을 입력하세요."); // 경고 창 표시
      setLoading(false);
      return;
    }

    console.log("[QASection] Points available:", points); // 현재 포인트 로그

    if (points < 5) {
      alert("포인트가 부족합니다. 최소 5포인트가 필요합니다."); // 경고 창 표시
      setLoading(false);
      return;
    }
    const accessToken = localStorage.getItem("access_token");
    console.log("[QASection] Retrieved accessToken:", accessToken); // 토큰 확인 로그

    try {
      console.log("[QASection] Sending query to the server...");
      console.log(
        "[QASection] Actual request URL:",
        api.defaults.baseURL + "/query"
      );
      console.log("[QASection] Question:", question);
      console.log("[QASection] Selected Name:", selectedName);
      console.log("[QASection] Is Premium:", isPremium);

      const response = await api.post(
        api.defaults.baseURL + "/query/",
        {
          question,
          selected_name: selectedName,
          is_premium: isPremium,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        }
      );
      console.log("[QASection] Request config:", response.config);

      console.log("[QASection] POST /query request URL:", response.config.url); // 요청 URL 확인 로그
      console.log("[QASection] Response received from server:", response.data);
      setAnswer(response.data.answer);
      setPoints((prevPoints) => prevPoints - 5);

      if (isPremium && response.data.retrieved_docs) {
        setRetrievedDocs(response.data.retrieved_docs);
        console.log(
          "[QASection] Retrieved documents:",
          response.data.retrieved_docs
        );
      }
    } catch (error) {
      console.error("[QASection] Error submitting question:", error);
      if (error.response) {
        console.error(
          "[QASection] POST /query server responded with:",
          error.response.status,
          error.response.data
        );
        setError(error.response.data.detail || error.response.statusText);
      } else {
        console.error("[QASection] Error message:", error.message);
        setError(error.message);
      }
    } finally {
      setLoading(false);
      console.log("[QASection] QA process completed.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <select
          value={selectedName}
          onChange={(e) => setSelectedName(e.target.value)}
        >
          {names.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        <input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="질문을 입력하세요"
        />
        <button type="submit" disabled={loading}>
          {loading ? "처리 중..." : "제출"}
        </button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {answer && (
        <div>
          <h3>답변:</h3>
          <ReactMarkdown>{answer}</ReactMarkdown>
        </div>
      )}
      {isPremium && retrievedDocs.length > 0 && (
        <div>
          <h3>관련 문서:</h3>
          {retrievedDocs.map((doc, index) => (
            <div key={index}>
              <h4>문서 {index + 1}</h4>
              <ReactMarkdown>{`**내용:** ${doc.content}`}</ReactMarkdown>
              <ReactMarkdown>{`**출처:** ${
                doc.metadata.이름 || "알 수 없음"
              }`}</ReactMarkdown>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default QASection;

