// src/components/ForgotPassword.js
import React, { useState } from "react";
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handlePasswordReset = async () => {
    try {
      const response = await axios.post(
        "http://localhost:5000/forgot-password",
        { email }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(
        error.response
          ? error.response.data.message
          : "Error sending password reset email"
      );
    }
  };

  return (
    <div>
      <h2>비밀번호 찾기</h2>
      <input
        type="email"
        placeholder="이메일 입력"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handlePasswordReset}>비밀번호 재설정</button>
      <p>{message}</p>
    </div>
  );
}

export default ForgotPassword;
