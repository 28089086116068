import { useState, useEffect } from "react";
import axios from "axios";
import QASection from "./QASection";
import { useNavigate } from "react-router-dom";

function UserPage({ username, setUsername, role, points, setPoints }) {
  const [tab, setTab] = useState("searchAssistant");
  const [userData, setUserData] = useState({});
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  console.log("UserPage============");

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        setMessage("User not authenticated. Redirecting to login...");
        navigate("/login");
        return;
      }

      const response = await axios.get("/user", {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("User data fetched:", response.data);

      setUserData(response.data);
      updateLocalState(response.data);
    } catch (error) {
      handleApiError(error, "Failed to fetch user data.");
    }
  };

  const updateLocalState = (data) => {
    setUsername(data.username || "");
    setPoints(data.points || 0);
    setPhoneNumber(data.phoneNumber || "");
    setGender(data.gender || "");
    setAddress(data.address || "");
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setMessage("새 비밀번호가 일치하지 않습니다.");
      return;
    }
    try {
      console.log("Changing password");
      const token = localStorage.getItem("access_token");
      await axios.put(
        "/user/changePassword",
        {
          currentPassword,
          newPassword,
          confirmPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      setMessage("비밀번호가 성공적으로 변경되었습니다.");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      handleApiError(error, "비밀번호 변경 중 오류가 발생했습니다.");
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        setMessage("User not authenticated. Redirecting to login...");
        navigate("/login");
        return;
      }

      console.log("Updating profile");
      const response = await axios.put(
        "/user/update",
        {
          username,
          phoneNumber,
          gender,
          address,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setMessage("프로필이 성공적으로 업데이트되었습니다.");
        console.log("Server response after update:", response.data);

        const updatedUserData = response.data.user;
        setUserData(updatedUserData);
        updateLocalState(updatedUserData);

        // 새 토큰 저장
        if (response.data.access_token) {
          localStorage.setItem("access_token", response.data.access_token);
        }

        await fetchUserData();
      }
    } catch (error) {
      handleApiError(error, "프로필 업데이트 중 오류가 발생했습니다.");
    }
  };

  const refreshToken = async (newUsername) => {
    try {
      const response = await axios.post("/refresh-token", {
        username: newUsername,
      });
      return response.data.access_token;
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  };

  const handleDeactivateAccount = async () => {
    if (window.confirm("정말 계정을 비활성화하시겠습니까?")) {
      try {
        console.log("Deactivating account");
        const token = localStorage.getItem("access_token");
        await axios.put(
          "/user/deactivate",
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
        setMessage("계정이 성공적으로 비활성화되었습니다. 로그아웃됩니다.");
        setTimeout(() => {
          localStorage.removeItem("access_token");
          navigate("/login");
        }, 2000);
      } catch (error) {
        handleApiError(error, "계정 비활성화 중 오류가 발생했습니다.");
      }
    }
  };

  const handleApiError = (error, defaultMessage) => {
    console.error(
      "API Error:",
      error.response ? error.response.data : error.message
    );
    if (error.response && error.response.status === 401) {
      setMessage("인증이 만료되었습니다. 다시 로그인해 주세요.");
      localStorage.removeItem("access_token");
      navigate("/login");
    } else {
      setMessage(error.response?.data?.detail || defaultMessage);
    }
  };

  return (
    <div>
      <div className="tabs">
        <button onClick={() => setTab("searchAssistant")}>
          서치 어시스턴트
        </button>
        <button onClick={() => setTab("myPage")}>내 페이지</button>
      </div>
      {tab === "searchAssistant" && (
        <div className="search-assistant">
          <QASection
            isPremium={role === "premium"}
            points={points}
            setPoints={setPoints}
          />
        </div>
      )}
      {tab === "myPage" && (
        <div className="my-page">
          <h2>내 페이지</h2>
          <p>사용자 이름: {userData.username}</p>
          <p>이메일: {userData.email}</p>
          <p>전화번호: {userData.phoneNumber}</p>
          <p>성별: {userData.gender}</p>
          <p>주소: {userData.address}</p>
          <div>
            <h3>비밀번호 변경</h3>
            <input
              type="password"
              placeholder="현재 비밀번호"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="새 비밀번호"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="새 비밀번호 확인"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button onClick={handlePasswordChange}>비밀번호 변경</button>
          </div>
          <div>
            <h3>프로필 업데이트</h3>
            <input
              type="text"
              placeholder="사용자 이름"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="tel"
              placeholder="전화번호"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="">성별 선택</option>
              <option value="male">남성</option>
              <option value="female">여성</option>
            </select>
            <input
              type="text"
              placeholder="주소"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <button onClick={handleUpdateProfile}>프로필 업데이트</button>
          </div>
          <div>
            <button className="deactivate" onClick={handleDeactivateAccount}>
              계정 비활성화
            </button>
          </div>
          <p className="message">{message}</p>
        </div>
      )}
    </div>
  );
}

export default UserPage;
