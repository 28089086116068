import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import AdminPage from "./components/AdminPage";
import UserPage from "./components/UserPage";
import ForgotPassword from "./components/ForgotPassword";
import "./index.css";
import "./App.css";

// Home과 NotFound 컴포넌트를 미리 정의합니다.
function Home() {
  return (
    <div>
      <h2>Welcome to the Home Page</h2>
      <Link to="/login">Go to Login</Link>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      <h2>404 - Page Not Found</h2>
      <Link to="/">Go to Home</Link>
    </div>
  );
}

// axios 설정
axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8000"; // FastAPI 서버의 주소

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const fetchUserData = async () => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await axios.get("/user", {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

function App() {
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [points, setPoints] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        setIsAuthenticated(false);
        navigate("/login");
        return;
      }

      const userData = await fetchUserData();

      if (userData) {
        setUsername(userData.username);
        setPoints(userData.points);
        setIsAuthenticated(true);

        const userRole = localStorage.getItem("role");
        if (userRole) {
          setRole(userRole);
          if (userRole === "admin") {
            navigate("/admin");
          } else {
            navigate("/user");
          }
        }
      } else {
        setIsAuthenticated(false);
        navigate("/login");
      }
    } catch (error) {
      setIsAuthenticated(false);
      navigate("/login");
    }
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("role");
    setIsAuthenticated(false);
    setUsername("");
    setRole("");
    navigate("/login");
  };

  return (
    <div className="container">
      <h1>오픈북 AI</h1>
      {isAuthenticated ? (
        <div>
          <p>
            환영합니다, {username}님! (포인트: {points})
          </p>
          <button onClick={logout} className="logout-button">
            로그아웃
          </button>
          {role === "admin" ? (
            <AdminPage username={username} />
          ) : (
            <UserPage
              username={username}
              setUsername={setUsername}
              role={role}
              points={points}
              setPoints={setPoints}
            />
          )}
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
      <div className="links">
        <a
          href="https://buymeacoffee.com/synomni"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          후원하기
        </a>
        <a
          href="https://open.kakao.com/o/gf0AYRCg"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          학점마법사 카톡방 (pw: study)
        </a>
      </div>
    </div>
  );
}

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const register = async () => {
    if (password !== confirmPassword) {
      setMessage("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      console.log("Registering user:", email);
      const response = await axios.post("/register", {
        username,
        email,
        password,
        confirmPassword,
        phoneNumber,
        gender,
        address,
      });

      if (response.data) {
        setMessage("회원가입 성공!");
        navigate("/");
      } else {
        setMessage("회원가입 실패!");
      }
    } catch (error) {
      setMessage(`회원가입 실패: ${error.message}`);
      console.error("Error during register request:", error.message);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="사용자 이름"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="email"
        placeholder="이메일"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="비밀번호"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="비밀번호 확인"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <input
        type="tel"
        placeholder="전화번호 (선택 사항)"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <select value={gender} onChange={(e) => setGender(e.target.value)}>
        <option value="">성별 선택 (선택 사항)</option>
        <option value="male">남성</option>
        <option value="female">여성</option>
      </select>
      <input
        type="text"
        placeholder="주소 (선택 사항)"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      <button onClick={register}>회원가입</button>
      <p className="message">{message}</p>
    </div>
  );
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    try {
      const params = new URLSearchParams();
      params.append("username", email);
      params.append("password", password);

      const response = await axios.post("/token", params);

      const { access_token, role } = response.data;
      if (access_token) {
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("role", role);
        window.location.reload(); // 새로고침을 통해 인증된 상태로 이동
      }
    } catch (error) {
      console.error("로그인 실패:", error);
    }
  };

  return (
    <div>
      <input
        type="email"
        placeholder="이메일"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="비밀번호"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={login}>로그인</button>
      <Link to="/register">회원가입</Link>
    </div>
  );
}

export default App;

